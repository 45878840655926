import React, { FC, ReactElement } from 'react'

import Layout from '../components/layout'
// import SEO from '@martiandev/seo'
import Container from '../components/container'

const NotFoundPage: FC = (): ReactElement => (
  <Layout title="Page Not Found">
    <Container>
      <h1>NOT FOUND</h1>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
